import { useState, useEffect } from "react";

import Head from "next/head";
import Image from "next/image";
import TextTransition, { presets } from "react-text-transition";

import ContactModal from "@components/ContactModal";
import Footer from "@components/Footer";
import HeaderNavigation from "@components/HeaderNavigation";
import PartnerCarousel from "@components/PartnerCarousel";
import VideoModal from "@components/VideoModal";

import InspiredCapitalLogo from "@components/svg/investors/InspiredCapitalLogo";
import ZiggCapitalLogo from "@components/svg/investors/ZiggCapitalLogo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faPlug,
  faCog,
  faStopwatch,
  faUsers,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import dynamic from 'next/dynamic'
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

import ContactButton from "@components/ContactButton";
import SubscribeButton from "@components/SubscribeButton";

import * as ga from "@utils/ga";
import SharedMetaTags from "@components/SharedMetaTags";

export default function Home() {
  const [windowWidth, setWindowWidth] = useState<number>(0);
  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      setWindowWidth(window.innerWidth);

      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    } else {
      setWindowWidth(1024);
    }
  }, []);

  let isMobile: boolean = windowWidth <= 768;

  const heroReservationClick = (evt: any) => {
    ga.event("click.play-promo-video", {
      event_label: "Reserve Now Hero Click",
      event_category: "reserve_click",
    });
  };
  // hype video modal state
  let [showVideoModal, setShowVideoModal] = useState(false);
  const openVideoModal = (evt: any) => {
    ga.event("click.play-promo-video", {
      event_label: "Played Promo Video",
      event_category: "promo_video",
    });

    if (!isMobile) {
      evt.preventDefault();
      setShowVideoModal(true);
    }
  };
  const closeVideoModal = (evt: any) => {
    evt.preventDefault();
    setShowVideoModal(false);
  };

  const MOW_HEADER = ["Smarter", "Safer", "Faster", "More"];

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <Head>
        <title>Scythe - Robots for a Greener World</title>
        <meta
          name="description"
          content="Self-driving, all-electric mowers for commercial landscaping.  Learn how Scythe can help your business do more."
        />
        <meta
          name="keywords"
          content="scythe, scythe robotics, autonomous mower, self-driving mower, commercial landscaping, landscaping, electric mower"
        />

        <link rel="canonical" href="/" />
        <SharedMetaTags />
      </Head>

      <HeaderNavigation />
      <div id="hero" className="text-light">
        <div className="overlay"></div>

        <div className="container pt-5 mt-5">
          <div className="row">
            <div className="col-11 col-lg-9">
              <div id="hero-text" className="my-3">
                {/* <h5 className="text-muted animated fadeInUp thin italic">Smell grass, not gas</h5> */}
                {/* <h1 className="display-3">
                  Robots for a <div className="d-none d-md-block"></div>
                  <span className="text-primary">Greener</span> World
                </h1> */}
                <h1 className="display-3" style = {{fontWeight:'1000'}}>
                  Upgrade your fleet, <br />
                  <span style = {{color: '#00b140'}}>Level-up</span> your business.
                </h1>

                <p>
                  Get more done with Scythe's all-new M.52. The industry's most
                  advanced autonomous commercial mower.
                </p>
                <p>Reservations are now open, and filling fast.</p>
                <span className="d-md-block">
                  <a
                    className="btn btn-skew  px-4"
                    style = {{backgroundColor: '#00b140', color: '#FFF'}}
                    href="https://scytherobotics-21409558.hs-sites.com/scythe-robotics-order-now"
                    onClick={heroReservationClick}
                  >
                    <span className="unskew">&nbsp; Order Now</span>
                  </a>

                  <a
                    className="watch-btn btn btn-skew btn-outline-light px-4"
                    href="https://www.youtube.com/watch?v=wqwDldk2zH8"
                    onClick={openVideoModal}
                    target="_blank"
                  >
                    <span className="unskew">
                      <FontAwesomeIcon icon={faPlay} size="sm" />
                      &nbsp; Watch Scythe Work
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <ReactPlayer
          controls={false}
          playsinline={true}
          muted={true}
          // url="/img/bg_clip.mp4"
          url={[
            { src: "/img/bg-clip.webm", type: "video/webm" },
            { src: "/img/bg-clip.mp4", type: "video/mp4" },
          ]}
          loop={true}
          playing={!showVideoModal}
          config={{
            file: {
              attributes: {
                poster: "/img/hero-video-placeholder.jpg",
              },
            },
          }}
        />
      </div>

      <div
        className="container-fluid p-0 bg bg-middle-center text-white page-section"
        id="home-mower"
      >
        <div className="page-section row d-block pt-5 pt-md-0 d-md-flex justify-content-center flex-column">
          <Image
            className="bg-img"
            src="/img/hero2_mower.jpg"
            layout="fill"
            objectFit="cover"
            objectPosition="30%"
          />
          <div className="col-12 col-md-5 offset-md-7 px-5" style={{zIndex: '1'}}>
            <div className="row">
              <div className="col-12">
                <h1 className="mow-heading display-3 extra-thick">
                  Mow{" "}
                  <TextTransition
                    inline
                    text={MOW_HEADER[index % MOW_HEADER.length]}
                    springConfig={presets.default}
                    direction="down"
                  />
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-10">
                <p>
                  Expand your crews' coverage with autonomous machines. Do more
                  with the team you've already got.
                </p>
              </div>
            </div>

            <div className="row text-upperspace text-left thick d-none d-md-block">
              <div className="col-12 p-1">
                <FontAwesomeIcon
                  icon={faStopwatch}
                  className="text-white ml-3"
                />{" "}
                Productive
              </div>
              <div className="col-12 p-1">
                <FontAwesomeIcon icon={faPlug} className="text-white ml-3" />{" "}
                Sustainable
              </div>
              <div className="col-12 p-1">
                <FontAwesomeIcon icon={faCog} className="text-white ml-3" />{" "}
                Reliable
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-0 pt-lg-5 pb-lg-5">
        <div className="row">
          <div className="col-12 col-lg-7 px-5 mb-lg-5 slant-right-white">
            {/* <h5 className="text-muted d-block d-sm-none d-xs-none">Why Scythe?</h5> */}
            <h2 className="mb-5 display-4">
              <strong style={{color: '#00b140', fontWeight: '1000'}}>A Cut Above</strong> The Rest
            </h2>
            <div className="row mb-4">
              <div className="col-md-1 col-xs-12">
                <FontAwesomeIcon
                  icon={faUsers}
                  style = {{color: '#00b140'}}
                  size="2x"
                />
              </div>
              <div className="col-12 col-md-10">
                <h4 className="font-weight-bold mb-3">
                  Multiply Your Workforce
                </h4>
                <p className="grey-text">
                  Scythe’s state-of-the-art autonomous machines handle the
                  mowing while your team works hard to keep your properties
                  looking their best.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-1 col-xs-12">
                <FontAwesomeIcon
                  icon={faLeaf}
                  style = {{color: '#00b140'}}
                  size="2x"
                />
              </div>
              <div className="col-12 col-md-10">
                <h4 className="font-weight-bold mb-3">Smell Grass, Not Gas</h4>
                <p className="grey-text">
                  Leave the noise and gas fumes behind. With all day battery
                  life and high torque electric motors, Scythe gives you power
                  without pollution.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-5 text-center px-0 slant-right-bg slant-right-bg-mower">
            <div className="deskew-img">
              <Image
                priority={true}
                className="bg-img"
                src="/img/drone_edging.jpg"
                layout="fill"
                objectFit="cover"
                objectPosition="30%"
                alt="Smell Grass, Not Gas."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light container-fluid mt-0 mt-lg-5">
        <div id="clients" className="container py-4">
          <p className="text-muted text-center mx-auto mb-0">
            Our <strong>industry leading</strong> partners include:
          </p>
          <PartnerCarousel />
        </div>
      </div>
      <div className="container pt-5">
        <div className="row">
          <div className="col col-lg-8">
            <h2 className="display-3">
              A <strong style = {{color: '#00b140', fontWeight: '1000'}}>New</strong> Era
            </h2>
            <p className="lead text-muted">
              Equipment that works <strong>with you</strong> to grow your
              business.
            </p>
          </div>
        </div>

        <div id="difference2">
          <div className="container-fluid">
            <div className="row equal">
              <div className="col-xs-12 col-md-6 bg text-dark rounded-md misaligned-incentives-box">
                <div className="p-4 p-md-5 py-2">
                  <h3>
                    <strong>Misaligned</strong> Incentives
                  </h3>
                  <p className="pr-3 thick">
                    Dealers and manufacturers profit when machines break, not
                    when they work. The unsurprising result: machines that don’t
                    last.
                  </p>
                </div>
              </div>
              <div
                id="difference-mower"
                className="col-xs-12 col-md-6 bg bg-dark rounded-md btn-skew"
              >
                <div className="p-4 p-md-5 py-2 unskew">
                  <h3 className="extra-thick">
                    <span style = {{backgroundColor: '#00b140', fontWeight: '1000'}} className="px-2 rounded">Guaranteed</span>
                    <span className="my-1"> Reliability</span>
                  </h3>
                  <p className="pr-3 text-shadow">
                    Autonomous machines that only bill when they’re working.
                    Scythe wins when its customers grow, not when they suffer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light-to-dark pt-0 pt-md-5 pb-3">
        <div id="cta" className="container">
          <div className="row jumbotron p-4 p-md-5 text-white rounded bg-primary">
            {/*  */}
            <div style = {{zIndex: '1'}} className="col-12 col-lg-8 px-0 text-left">
              <h2 className="extra-thick">Do More With Scythe</h2>
              <p className="my-3">
                Emissions-free machines that multiply your team's ability to
                care for the outdoors.
              </p>
            </div>
            {/*  */}
            <div className="col-12 col-lg-4 do-more-buttons">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-10 offset-lg-2">
                  <ContactButton
                    className="btn btn-skew btn-light btn-lg text-primary px-4 m-2"
                    arrow={false}
                    thick={true}
                    track={() => {
                      ga.event("click.contact-index-green-cta", {
                        event_label: "Contact Us Homepage Green CTA",
                        event_category: "contact",
                      });
                    }}
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-10 offset-lg-2">
                  <SubscribeButton
                    className="btn btn-skew btn-outline-light btn-lg px-4 m-2"
                    track={() => {
                      ga.event("click.subscribe-index-green-cta", {
                        event_label: "Subscribe Homepage Green CTA",
                        event_category: "subscribe",
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
        {/* ./container */}
      </div>

      <div className="bg-dark">
        <div className="container">
          <p className="text-muted text-center mx-auto mb-0">
            Backed by the best partners in VC
          </p>

          <ul className="investors">
            <li>
              <a
                href="https://inspiredcapital.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="vc-logo">
                  <InspiredCapitalLogo fill="#ffffff" width={170} />
                </div>
              </a>
            </li>

            <li>
              <a
                href="https://trueventures.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="vc-logo">
                  <Image
                    alt=""
                    priority={true}
                    layout="intrinsic"
                    width={410}
                    height={69}
                    src="/img/true-ventures.png"
                    className="img-fluid"
                    data-pin-nopin={true}
                  />
                </div>
              </a>
            </li>

            <li>
              <a
                href="https://ziggcap.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="vc-logo">
                  <ZiggCapitalLogo height={40} fill="#ffffff" />
                </div>
              </a>
            </li>

            <li>
              <a
                href="https://lemnos.vc/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="vc-logo">
                  <Image
                    priority={true}
                    layout="intrinsic"
                    width={148}
                    height={35}
                    src="/img/lemnos_white.png"
                    className="img-fluid"
                    alt="Lemnos Hardware Fund"
                    data-pin-nopin={true}
                  />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Footer />

      <VideoModal
        open={openVideoModal}
        close={closeVideoModal}
        isOpen={showVideoModal}
      />
    </>
  );
}
